import { graphql } from "gatsby"
import React, { FC } from "react"
import { Layout } from "src/components/Layout"
import { JsonLd } from "gatsby-plugin-next-seo"
import { Props } from "./types"
import { usePages } from "src/context/pages"
import * as styles from "./styles.module.scss"
import FlowX from "src/components/Banners/FlowX"
import { useInView } from "react-intersection-observer"
import Tilt from "react-parallax-tilt"
import Logo from "src/images/structured-data-logo.png"
import Img from "gatsby-image"

const Template: FC<Props> = ({
  data: {
    file: { childYaml: texts },
  },
}) => {
  const { currentPath } = usePages()
  const [ref, inView] = useInView({
    threshold: 0,
    triggerOnce: true,
  })
  return (
    <Layout
      title={texts.metaTitle}
      description={texts.metaDescription}
      darkMode={true}
      openGraph={{
        title: texts.metaTitle,
        description: texts.metaDescription,
        url: `${process.env.GATSBY_CANONICAL_URL}${currentPath}`,
        images: [
          {
            url: `${process.env.GATSBY_CANONICAL_URL}${Logo}`,
            width: 800,
            height: 600,
            alt: "Flow logo",
          },
          {
            url: `${process.env.GATSBY_CANONICAL_URL}${Logo}`,
            width: 900,
            height: 800,
            alt: "Flow logo",
          },
        ],
      }}
      className={styles.flowX}
    >
      <JsonLd
        json={{
          "@context": "https://schema.org",
          "@type": "SoftwareApplication",
          "@id": "https://www.getflow.com/flow-x/#softwareapplication",
          name: "Flow X",
          description: "Flow X brings…",
          url: "https://www.getflow.com/flow-x/",
          mainEntityOfPage: "https://www.getflow.com/flow-x/",
          image: "",
        }}
      />
      <div className={`content-wrapper ${styles.flowXContent}`}>
        <h1 className={styles.title}>{texts.header}</h1>
        <Tilt
          className={styles.trackOnWindow}
          tiltMaxAngleX={5}
          tiltMaxAngleY={5}
          trackOnWindow={true}
        >
          <div
            className={`${styles.imgContainer} ${inView ? styles.show : ""}`}
            ref={ref}
          >
            <div className={styles.circlesContainer}>
              <svg
                className={styles.circlesContent}
                width="1204px"
                height="800px"
                viewBox="0 0 1204 800"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle
                  fill="rgba(57, 0, 122, 0.12)"
                  cx="210"
                  cy="210"
                  r="180"
                  stroke="rgba(57, 0, 122, 0.52)"
                  strokeWidth="2"
                ></circle>
                <circle
                  fill="rgba(57, 0, 122, 0.12)"
                  cx="calc(100% - 300px)"
                  cy="calc(100% - 300px)"
                  r="300"
                  stroke="rgba(57, 0, 122, 0.52)"
                  strokeWidth="2"
                ></circle>
              </svg>
            </div>
            <div className={styles.img}>
              <Img
                fluid={texts.image.childImageSharp.fluid}
                style={{ height: "100%", width: "100%" }}
              />
            </div>

            <div className={styles.badge}>
              <div className={styles.badgeContainer}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="320"
                  height="320"
                  viewBox="0 0 320 320"
                  className={styles.badgeBg}
                >
                  <path
                    id="User_Contained_Outline"
                    data-name="User Contained Outline"
                    fillRule="evenodd"
                    d="M160,320c158.765,0,160-71.635,160-160S318.765,0,160,0,0,71.635,0,160,1.234,320,160,320Z"
                  />
                </svg>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="162"
                  height="156"
                  viewBox="0 0 162 156"
                  className={styles.badgeSymbol}
                >
                  <path
                    id="X"
                    fillRule="evenodd"
                    fill="currentColor"
                    d="M161.665,149.288L63.478,7.649C59.8,2.364,56.218.461,50.109,0.461H4.6C1.23,0.461-.78,4.69,1.116,7.649L99.3,149.288c3.678,5.285,7.26,7.188,13.37,7.188H158.18C161.551,156.476,163.561,152.248,161.665,149.288ZM94.488,22.018a6.518,6.518,0,0,1,0-7.423L99.3,7.649c3.679-5.285,7.26-7.188,13.37-7.188H158.18c3.371,0,5.381,4.228,3.485,7.188L126.643,58.17a4.313,4.313,0,0,1-7.094,0ZM43.232,98.767a4.313,4.313,0,0,0-7.094,0L1.117,149.288c-1.9,2.96.114,7.188,3.485,7.188H50.109c6.11,0,9.691-1.9,13.37-7.188l4.815-6.946a6.516,6.516,0,0,0,0-7.422Z"
                  />
                </svg>
              </div>
            </div>
          </div>
        </Tilt>
        <p className={styles.text}>{texts.firstParagraph}</p>
        <p className={styles.text}>{texts.secondParagraph}</p>
        <p className={`${styles.text} ${styles.medium}`}>
          {texts.thirdParagraph}
        </p>
        <p className={`${styles.text} ${styles.medium} ${styles.color}`}>
          {texts.colorParagraph}
        </p>
        <div className={styles.banner} id="GetAccess">
          <FlowX variant="small" />
        </div>
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query($ymlPath: String!) {
    file(absolutePath: { eq: $ymlPath }) {
      childYaml {
        metaTitle
        metaDescription
        header
        firstParagraph
        secondParagraph
        thirdParagraph
        colorParagraph
        image {
          childImageSharp {
            fluid(quality: 100, maxWidth: 1084) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`

export default Template
